import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLogger } from '../common/hooks/useLogger'

const Home: React.FC = () => {
    const log = useLogger()
    const Home = dynamic(() => import('../components/Home'), { ssr: false })

    useEffect(() => {
        log.info('トップ画面表示')
    }, [log])

    return (
        <StyledContainer>
            <Home />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: lightgray;
`

export default Home
