/* eslint-disable no-console */
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { v4 as uuid_v4 } from 'uuid'
import packageJson from '../../../package.json'
import { jwtTokenState } from '../../store/UserState'

const POST_LOG = gql`
    mutation PostLog(
        $id: uuid = "00000000-0000-0000-0000-000000000000"
        $level: String = ""
        $app_name: String = ""
        $message: String = ""
        $detail: jsonb = ""
        $user_organization_id: uuid = "00000000-0000-0000-0000-000000000000"
    ) {
        insert_nile_operation_applog(
            objects: {
                app_name: $app_name
                datetime: "now"
                detail: $detail
                id: $id
                level: $level
                message: $message
                user_organization_id: $user_organization_id
            }
        ) {
            returning {
                id
            }
        }
    }
`

export interface Logger {
    error(message: string, detail?: any): void

    info(message: string, detail?: any): void

    warn(message: string, detail?: any): void

    debug(message: string, detail?: any): void
}

export const useLogger = (): Logger => {
    const token = useRecoilValue(jwtTokenState)

    const [insertLog] = useMutation(POST_LOG, {
        onError: (e) => {
            console.error('logの送信に失敗', e)
        },
    })

    const postLog = useCallback((level: string, message: string, detail?: any) => {
        insertLog({
            variables: {
                id: uuid_v4(),
                level,
                app_name: packageJson.name,
                message,
                detail,
                user_organization_id: token?.user_organization_id,
            },
        })
    }, [])

    const logger = useMemo(() => {
        return {
            error(message: string, detail?: any) {
                postLog('error', message, detail)
                console.error(message)
            },

            info(message: string, detail?: any) {
                postLog('info', message, detail)
                console.info(message)
            },

            warn(message: string, detail?: any) {
                postLog('warn', message, detail)
                console.warn(message)
            },

            debug(message: string, detail?: any) {
                postLog('debug', message, detail)
                console.debug(message)
            },
        }
    }, [postLog])

    return logger
}
